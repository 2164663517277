<script setup lang="ts">
import { computed } from 'vue'
import { formatNumber } from '/~/utils/format/numeric'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseImage from '/~/components/base/image/base-image.vue'
import { Activity } from '/~/composables/activity/core/activity'
import { useLocalization } from '/~/composables/localization'
import { useProvider } from '/~/composables/provider'
import { usePointsPrograms } from '/~/templates/bill-payments/composables'

const props = defineProps<{ item: Activity }>()

const { findPartner } = usePointsPrograms()
const { providerTitle } = useProvider()
const { formatBusinessNumber } = useLocalization()

const selectedPartner = computed(() => findPartner(props.item.subtype) as any)
const partnerIcon = computed(() => {
  return selectedPartner.value?.listIcon || selectedPartner.value?.icon
})
</script>

<template>
  <div>
    <h3 class="mt-14 text-base font-bold text-eonx-neutral-800 sm:mt-9">
      Member details
    </h3>

    <div class="my-6 flex h-12 items-center space-x-4">
      <div class="w-12">
        <div class="flex w-12 items-center justify-center">
          <base-image
            :src="partnerIcon"
            class="text-orange-700"
            :ratio="false"
          />
        </div>
      </div>
      <div class="ml-4 flex flex-col justify-between">
        <span class="font-bold text-eonx-neutral-800">
          {{ item.partnerName }}
        </span>
        <span class="text-sm text-eonx-neutral-600">
          {{ selectedPartner.accountNumberLabel }}
          {{ formatBusinessNumber(item.accountNumber) }}
        </span>
      </div>
    </div>

    <div class="border-t py-5">
      <h3 class="text-base font-bold text-eonx-neutral-800">Transferred</h3>

      <div class="mt-4 flex items-center justify-between">
        <div class="flex h-12 w-12 items-center justify-center">
          <base-icon class="text-primary" svg="points/token" :size="40" />
        </div>
        <div class="ml-4 flex-1 font-bold text-eonx-neutral-800">
          {{ providerTitle }} Points
        </div>
        <div class="text-sm font-bold text-eonx-neutral-600">
          -{{ formatNumber(item.points) }}
        </div>
      </div>

      <div class="mt-4 flex items-center justify-between">
        <div class="flex h-12 w-12 flex-shrink-0 items-center justify-center">
          <base-image
            :src="partnerIcon"
            class="text-orange-700"
            :ratio="false"
          />
        </div>
        <div class="ml-4 flex-1 font-bold text-eonx-neutral-800">
          {{ selectedPartner?.programCurrency }}
        </div>
        <div class="text-sm font-bold text-eonx-neutral-600">
          +{{ formatNumber(item.airlinePoints) }}
        </div>
      </div>
    </div>
  </div>
</template>
